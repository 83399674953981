import { useEffect, useState } from "react";
import { NavLink as NavLinkRRD } from "react-router-dom";
import Users from "views/Users";
import UserForm from "views/UserForm";
import Clients from "views/Clients";
import ClientForm from "views/ClientForm";
import Segments from "views/Segments";
import SegmentForm from "views/SegmentForm";
import Favorites from "views/Favorites";
import Login from "views/Login";
import TopFavorites from "views/TopFavorites";
import ContentForm from "views/ContentForm.js";
import Content from "views/Content.js";
import Metrics from "views/Metric";
import MetricRuleClientList from "views/MetricRuleClientList";
import UploadMetrics from "views/UploadMetricsFile";
import Prompts from "views/Prompts";
import PromptForm from "views/PromptForm";
import UploadAttendances from "views/UploadAttendances";
import Attendances from "views/Attendances";
import MeuSputiContent from "views/ContentMeuSputi";
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
} from "reactstrap";
import Roles from "views/Roles";
import RoleForm from "views/RoleForm";
import IpGroup from "views/ipGroup";
import { useMsal } from "@azure/msal-react";
import ModalConfirm from "components/Modal/ModalConfirm";
import Templates from "views/Templates";
import TemplateForm from "views/TemplateForm";
import AnalysisProcesses from "views/AnalysisProcesses";
import ReusableElements from "views/ReusableElements";
import PromptScoreForm from "views/PromptScoreForm";
import EvaluableEventForm from "views/EvaluableEventForm";
import QualityAttendances from "views/QualityAttendances";
import EvaluableEvent from "views/EvaluableEvent";
import QualityScoreRules from "views/QualityScoreRules";
import QualityContestation from "views/QualityContestation";

const Sidebar = ({ routes }) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const permissions = localStorage.getItem("permissions");
  const { instance } = useMsal();
  const [menus, setMenus] = useState([
    "client",
    "user",
    "sputi",
    "analysis",
    "quality",
  ]);

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleLogout = async () => {
    localStorage.removeItem("permissions");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("admin_username");

    const keys = Object.entries(localStorage).map(([key, value]) => [
      key,
      value,
    ]);
    for (const key of keys) {
      if (key[0].startsWith("score_rule")) {
        localStorage.removeItem(key[0]);
      }
    }

    const azureUser = localStorage.getItem("azure_user");
    if (azureUser) {
      try {
        await instance
          .logoutPopup()
          .then(() => {
            localStorage.removeItem("azure_user");
          })
          .catch((error) => {
            console.log("Error Logout: " + error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (permissions && prop.name !== "Logout") {
        return (
          <NavItem key={key}>
            {permissions?.includes(prop.action) && (
              <NavLink
                style={{
                  fontSize: "0.84em",
                }}
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={() => {
                  closeCollapse();
                }}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            )}
          </NavItem>
        );
      }

      return (
        <NavItem key={key}>
          <ModalConfirm
            modalText={"Deseja mesmo sair?"}
            handler={async () => {
              if (prop.name === "Logout") {
                await handleLogout();
              }
              closeCollapse();
              window.location.pathname = prop.layout + prop.path;
            }}
            sidebar={{ layout: prop.layout, path: prop.path }}
            icon={prop.icon}
            buttonText={prop.name}
          />
        </NavItem>
      );
    });
  };

  const handleMenu = (menuName) => {
    if (menus.includes(menuName)) {
      setMenus((menus) => menus.filter((m) => m !== menuName));
      localStorage.setItem(
        "menus",
        JSON.stringify(menus.filter((m) => m !== menuName)),
      );
    } else {
      setMenus((menus) => [...menus, menuName]);
      localStorage.setItem("menus", JSON.stringify([...menus, menuName]));
    }
  };

  useEffect(() => {
    const storagedMenus = localStorage.getItem("menus");
    setMenus(
      JSON.parse(storagedMenus) || [
        "client",
        "user",
        "sputi",
        "analysis",
        "quality",
      ],
    );
  }, []);

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      style={{
        width: "calc(200px + 17px)",
        boxSizing: "content-box",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
      expand="md"
      id="sidenav-main"
    >
      <Container fluid style={{ marginBottom: "40px" }}>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <Collapse navbar isOpen={collapseOpen}>
          <img
            alt="logo"
            className="sidebar-logo"
            src={require("assets/img/brand/logo.png")}
          />

          <hr className="my-3" />

          <Nav navbar>
            <div className="ml-4">
              <p style={{ fontSize: "14px" }}>
                <b>{localStorage.getItem("admin_username")}</b>
              </p>
            </div>
            {createLinks([
              {
                name: "Logout",
                icon: "ni ni-user-run",
                path: "/login",
                layout: "/auth",
                component: Login,
              },
            ])}
          </Nav>

          <div>
            {[
              "read:client",
              "create:client",
              "read:segment",
              "create:segment",
            ].some((permission) => permissions?.includes(permission)) && (
              <>
                <hr className="my-3" />
                <Button
                  onClick={() => handleMenu("client")}
                  style={{ borderRadius: "2px", width: "100%" }}
                >
                  <b>Clientes</b>
                </Button>
                {menus.includes("client") && (
                  <Nav navbar>
                    {createLinks([
                      {
                        name: "Clientes",
                        action: "read:client",
                        icon: "ni ni-support-16 text-blue",
                        path: "/clients",
                        layout: "/admin",
                        component: Clients,
                      },
                      {
                        name: "Cadastro de Clientes",
                        action: "create:client",
                        icon: "ni ni-collection text-blue",
                        path: "/clients-form",
                        layout: "/admin",
                        component: ClientForm,
                      },
                      {
                        name: "Segmentos",
                        action: "read:segment",
                        icon: "ni ni-support-16 text-blue",
                        path: "/segments",
                        layout: "/admin",
                        component: Segments,
                      },
                      {
                        name: "Cadastro de Segmentos",
                        action: "create:segment",
                        icon: "ni ni-collection text-blue",
                        path: "/segments-form",
                        layout: "/admin",
                        component: SegmentForm,
                      },
                      {
                        name: "Grupos de ips",
                        action: "read:ip_group",
                        icon: "ni ni-bullet-list-67 text-blue",
                        path: "/ip-group",
                        layout: "/admin",
                        component: IpGroup,
                      },
                    ])}
                  </Nav>
                )}
              </>
            )}
            {["read:user", "create:user", "read:role", "create:role"].some(
              (permission) => permissions?.includes(permission),
            ) && (
              <>
                <hr className="my-3" />
                <Button
                  onClick={() => handleMenu("user")}
                  style={{ borderRadius: "2px", width: "100%" }}
                >
                  <b>Usuários</b>
                </Button>
                {menus.includes("user") && (
                  <Nav navbar>
                    {createLinks([
                      {
                        name: "Usuários",
                        action: "read:user",
                        icon: "ni ni-single-02 text-green",
                        path: "/users",
                        layout: "/admin",
                        component: Users,
                      },
                      {
                        name: "Cadastro de Usuários",
                        action: "create:user",
                        icon: "ni ni-collection text-green",
                        path: "/users-form",
                        layout: "/admin",
                        component: UserForm,
                      },
                      {
                        name: "Cargos",
                        action: "read:role",
                        icon: "ni ni-badge text-green",
                        path: "/roles",
                        layout: "/admin",
                        component: Roles,
                      },
                      {
                        name: "Cadastro de cargo",
                        action: "create:role",
                        icon: "ni ni-collection text-green",
                        path: "/roles-form",
                        layout: "/admin",
                        component: RoleForm,
                      },
                    ])}
                  </Nav>
                )}
              </>
            )}

            {[
              "create:content",
              "read:content",
              "read:favorite",
              "read:top_favorite",
              "read:metric",
              "read:rule",
              "upload:metric",
              "read:my_sputi_content",
            ].some((permission) => permissions?.includes(permission)) && (
              <>
                <hr className="my-3" />
                <Button
                  onClick={() => handleMenu("sputi")}
                  style={{ borderRadius: "2px", width: "100%" }}
                >
                  <b>SPUTI</b>
                </Button>
                {menus.includes("sputi") && (
                  <Nav navbar>
                    {createLinks([
                      {
                        name: "Adicionar conteúdo",
                        action: "create:content",
                        path: "/formulario-conteudo",
                        icon: "ni ni-bulb-61 text-red",
                        component: ContentForm,
                        layout: "/admin",
                      },
                      {
                        name: "Conteúdos",
                        action: "read:content",
                        path: "/conteudos",
                        icon: "ni ni-bullet-list-67 text-red",
                        component: Content,
                        layout: "/admin",
                      },
                      {
                        name: "Favoritos",
                        action: "read:favorite",
                        icon: "ni ni-favourite-28 text-red",
                        path: "/favorites",
                        layout: "/admin",
                        component: Favorites,
                      },
                      {
                        name: "Top Favoritos",
                        action: "read:top_favorite",
                        icon: "ni ni-trophy text-red",
                        path: "/top-favorites",
                        layout: "/admin",
                        component: TopFavorites,
                      },
                      {
                        name: "Metricas",
                        action: "read:metric",
                        icon: "ni ni-user-run text-red",
                        path: "/metrics",
                        layout: "/admin",
                        component: Metrics,
                      },
                      {
                        name: "Regras do cliente",
                        action: "read:rule",
                        icon: "ni ni-settings text-red",
                        path: "/clients-rules",
                        layout: "/admin",
                        component: MetricRuleClientList,
                      },
                      {
                        name: "Carregar arquivo de metricas",
                        action: "upload:metric",
                        icon: "ni ni-cloud-upload-96 text-red",
                        path: "/upload-metrics",
                        layout: "/admin",
                        component: UploadMetrics,
                      },
                      {
                        name: "Conteúdos Meu Sputi",
                        action: "read:my_sputi_content",
                        path: "/conteudos-meu-sputi",
                        icon: "ni ni-bullet-list-67 text-red",
                        component: MeuSputiContent,
                        layout: "/admin",
                      },
                    ])}
                  </Nav>
                )}
              </>
            )}

            {[
              "upload:attendance",
              "read:analysed_attendance",
              "read:prompt",
              "create:prompt",
              "read:prompt:nonprocessable",
              "create:prompt:nonprocessable",
              "read:reusable_element",
            ].some((permission) => permissions?.includes(permission)) && (
              <>
                <hr className="my-3" />
                <Button
                  onClick={() => handleMenu("analysis")}
                  style={{ borderRadius: "2px", width: "100%" }}
                >
                  <b>Avaliador</b>
                </Button>
                {menus.includes("analysis") && (
                  <Nav navbar>
                    {createLinks([
                      {
                        name: "Status de processamentos",
                        action: "read:batch",
                        icon: "ni ni-sound-wave text-purple",
                        path: "/analysis-processes",
                        layout: "/admin",
                        component: AnalysisProcesses,
                      },
                      {
                        name: "Carregar atendimentos",
                        action: "upload:attendance",
                        icon: "ni ni-cloud-upload-96 text-purple",
                        path: "/upload-attendances",
                        layout: "/admin",
                        component: UploadAttendances,
                      },
                      {
                        name: "Atendimentos analisados",
                        action: "read:analysed_attendance",
                        icon: "ni ni-atom text-purple",
                        path: "/attendances",
                        layout: "/admin",
                        component: Attendances,
                      },
                      {
                        name: "Templates",
                        action: "read:template",
                        icon: "ni ni-books text-purple",
                        path: "/templates",
                        layout: "/admin",
                        component: Templates,
                      },
                      {
                        name: "Cadastro de Template",
                        action: "create:template",
                        icon: "ni ni-collection text-purple",
                        path: "/template-form",
                        layout: "/admin",
                        component: TemplateForm,
                      },
                      {
                        name: "Prompts",
                        action: "read:prompt",
                        icon: "ni ni-books text-purple",
                        path: "/prompts",
                        layout: "/admin",
                        component: Prompts,
                      },
                      {
                        name: "Cadastro de Prompts",
                        action: "create:prompt",
                        icon: "ni ni-collection text-purple",
                        path: "/prompts-form",
                        layout: "/admin",
                        component: PromptForm,
                      },
                      {
                        name: "Elementos reutilizáveis",
                        action: "read:reusable_element",
                        icon: "ni ni-books text-purple",
                        path: "/reusable-elements",
                        layout: "/admin",
                        component: ReusableElements,
                      },
                    ])}
                  </Nav>
                )}
              </>
            )}
            {[
              "read:quality:analysed_attendance",
              "create:quality:score_rule",
              "read:quality:score_rule",
              "create:quality:evaluable_event",
              "read:quality:evaluable_event",
            ].some((permission) => permissions?.includes(permission)) && (
              <>
                <hr className="my-3" />
                <Button
                  onClick={() => handleMenu("quality")}
                  style={{ borderRadius: "2px", width: "100%" }}
                >
                  <b>Qualidade</b>
                </Button>
                {menus.includes("quality") && (
                  <Nav navbar>
                    {createLinks([
                      {
                        name: "Atendimentos Avaliados",
                        action: "read:quality:analysed_attendance",
                        icon: "ni ni-atom text-muted",
                        path: "/quality-attendances",
                        layout: "/admin",
                        component: QualityAttendances,
                      },
                      {
                        name: "Cadastrar Regras de Score",
                        action: "create:quality:score_rule",
                        icon: "ni ni-collection text-muted",
                        path: "/prompt-score-form",
                        layout: "/admin",
                        component: PromptScoreForm,
                      },
                      {
                        name: "Regras de Score",
                        action: "read:quality:score_rule",
                        icon: "ni ni-collection text-muted",
                        path: "/score-rules",
                        layout: "/admin",
                        component: QualityScoreRules,
                      },
                      {
                        name: "Revisar Contestações",
                        action: "read:quality:pending_contestation",
                        icon: "fab fa-stack-exchange text-muted",
                        path: "/contestations",
                        layout: "/admin",
                        component: QualityContestation,
                      },
                      {
                        name: "Cadastro de Eventos Avaliáveis",
                        action: "create:quality:evaluable_event",
                        icon: "ni ni-collection text-muted",
                        path: "/evaluable-event-form",
                        layout: "/admin",
                        component: EvaluableEventForm,
                      },
                      {
                        name: "Eventos Avaliáveis",
                        action: "read:quality:evaluable_event",
                        icon: "ni ni-books text-muted",
                        path: "/evaluable-event",
                        layout: "/admin",
                        component: EvaluableEvent,
                      },
                    ])}
                  </Nav>
                )}
              </>
            )}
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
};
export default Sidebar;
