import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "service/api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ModalAttendanceCSVExample from "components/Modal/ModalAttendanceCSVExample";
import Loading from "components/Loading";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Badge,
  Table,
} from "reactstrap";
import newApi from "service/newApi";

const UploadAttendances = () => {
  const { register } = useForm();
  const [loading, setLoading] = useState(false);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState();
  const [attendancesLoading, setAttendancesLoading] = useState(false);
  const [attendanceFilePath, setAttendanceFilePath] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [remainingBudget, setRemainingBudget] = useState(null);
  const [spentBudget, setSpentBudget] = useState(null);
  const [attendancesPrice, setAttendancesPrice] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const inputFileRef = useRef(null);

  const submit = () => {
    setLoading(true);

    if (attendancesLoading) {
      setLoading(false);
      return toast.warn("Aguarde o retorno de valores dos atendimentos");
    }

    if (!selectedSegment || !selectedSegment.value.trim()) {
      setLoading(false);
      return toast.error("Informe o segmento");
    }

    if (!attendanceFilePath || !attendanceFilePath.trim()) {
      setLoading(false);
      return toast.error("Necessário fazer upload dos atendimentos");
    }

    newApi
      .post("/v1/attendances/analyse", {
        attendance_csv_file_path: attendanceFilePath,
        segment_id: selectedSegment.value,
      })
      .then(() => {
        toast.success("Iniciado processamento para analise de atendimentos!");
        setTotalPrice(null);
        setAttendancesPrice([]);
        setAttendanceFilePath(null);
        setSelectedFileName(null);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response?.data?.message));
        setLoading(false);
      });
  };

  useEffect(() => {
    api
      .get(`/segments?page=1&limit=10000`)
      .then((response) => {
        setSegments(
          response.data.segments.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar os segmentos!");
        return;
      });
  }, []);

  const handleUploadedFile = async (files) => {
    if (!selectedSegment || !selectedSegment.value?.trim()) {
      return toast.error("Informe o segmento");
    }
    setAttendancesLoading(true);
    setSelectedFileName(files[0].name);
    const fd = new FormData();
    fd.append("file", files[0]);
    fd.append("segment_id", selectedSegment.value);
    newApi
      .post("/v1/attendances/upload", fd)
      .then((response) => {
        toast.success("Estimativa de preços gerada!");
        setLoading(false);
        setTotalPrice(response.data.total_price);
        setAttendancesPrice(response.data.attendances);
        setAttendanceFilePath(response.data.file_path);
        setAttendancesLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Erro ao realizar o upload dos atendimentos!");
        setAttendancesLoading(false);
        return;
      });
  };

  useEffect(() => {
    setLoading(true);
    if (selectedSegment) {
      api
        .get(`/segments/${selectedSegment.value}/budget`)
        .then((response) => {
          const { spentBudget, remainingBudget } = response.data;
          setSpentBudget(spentBudget);
          setRemainingBudget(remainingBudget);
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Ocorreu um erro ao recuperar o budget restante");
          setLoading(false);
        });
    } else {
      setRemainingBudget(null);
      setSpentBudget(null);
      setLoading(false);
    }
  }, [selectedSegment]);

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="" xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Carregar e analisar atendimentos</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody
              style={{
                position: "relative",
              }}
            >
              {loading ? <Loading /> : ""}
              <div className="pl-lg-12">
                <Col pl="12" lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="client">
                      Segmento
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Escolha um segmento"
                      value={selectedSegment}
                      onChange={setSelectedSegment}
                      isSearchable={true}
                      isClearable={true}
                      options={segments}
                    />
                    <div className="mt-4">
                      <label className="form-control-label" htmlFor="client">
                        Valor Restante em orçamento:{" "}
                        <Badge
                          color={remainingBudget < 0 ? "warning" : "success"}
                          style={{ fontSize: "0.9em" }}
                          title="
                            Indisponível temporariamente"
                        >
                          <i>
                            {/* {remainingBudget
                              ? `USD $${parseFloat(
                                  `${remainingBudget}`,
                                ).toFixed(2)}`
                              : ". . ."} */}
                          </i>
                          Indisponível temporariamente
                        </Badge>
                      </label>
                      <br />
                      <label className="form-control-label" htmlFor="client">
                        Valor Gasto:{" "}
                        <Badge
                          color="success"
                          style={{ fontSize: "0.9em" }}
                          title="Indisponível temporariamente"
                        >
                          <i>
                            {/* {spentBudget
                              ? `USD $${parseFloat(`${spentBudget}`).toFixed(
                                  2,
                                )}`
                              : ". . ."} */}
                          </i>
                          Indisponível temporariamente
                        </Badge>
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col pl="12" lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      Carregar atendimentos ( csv ){" "}
                      <ModalAttendanceCSVExample />
                    </label>
                    <div
                      style={{
                        width: "100%",
                        heigth: "100%",
                        backgroundColor: "#fff",
                        border: "1px dashed #cecece",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        inputFileRef.current?.click();
                      }}
                      onDragOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onDragEnter={(e) => {
                        e.stopPropagation();
                      }}
                      onDrop={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        if (
                          !e.dataTransfer.files ||
                          !e.dataTransfer.files.length
                        ) {
                          return toast.error("Arquivos não encontrados!");
                        }
                        handleUploadedFile(e.dataTransfer.files);
                      }}
                    >
                      <div
                        style={{
                          width: "80px",
                          height: "50px",
                          backgroundColor: "#f7fafc",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.7em",
                        }}
                      >
                        <i className={"ni ni-cloud-upload-96 text-#404040"} />
                      </div>
                      <div
                        style={{
                          fontSize: "0.8em",
                        }}
                      >
                        {selectedFileName
                          ? selectedFileName
                          : "Escolha um arquivo ou arraste para carregar"}
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={inputFileRef}
                      id="input-file"
                      style={{
                        display: "none",
                      }}
                      onChange={(e) => {
                        if (!e.target.files || !e.target.files.length) {
                          return toast.error("Arquivos não encontrados!");
                        }
                        handleUploadedFile(e.target.files);
                        if (
                          inputFileRef &&
                          inputFileRef.current &&
                          inputFileRef.current.value
                        )
                          inputFileRef.current.value = "";
                      }}
                    />
                  </FormGroup>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="client">
                      Valor total:{" "}
                      <Badge color="success" style={{ fontSize: "0.9em" }}>
                        <i>
                          {totalPrice
                            ? `USD $${parseFloat(`${totalPrice}`).toFixed(2)}`
                            : ". . ."}
                        </i>
                      </Badge>
                    </label>
                    <br />
                    <label className="form-control-label" htmlFor="client">
                      Valor por atendimento{" "}
                      {attendancesPrice.length
                        ? `( ${attendancesPrice.length} Atendimentos )`
                        : ""}
                    </label>
                    <div
                      className="styled-scroll"
                      style={{
                        width: "100%",
                        height: "500px",
                        overflow: "auto",
                        backgroundColor: "#fff",
                        border: "1px dashed #cecece",
                        position: "relative",
                      }}
                    >
                      {attendancesLoading ? <Loading /> : ""}
                      <Table
                        style={{
                          width: "100%",
                          tableLayout: "fixed",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Atendimento</th>
                            <th>Valor ( USD )</th>
                            <th>Prompts validos</th>
                            <th>Prompts invalidos</th>
                          </tr>
                        </thead>
                        <tbody>
                          {attendancesPrice.length
                            ? attendancesPrice.map((attendancePrice, index) => {
                                const hasInvalidPrompts = attendancePrice
                                  .invalid_prompts.length
                                  ? true
                                  : false;
                                const rowStyle = hasInvalidPrompts
                                  ? {
                                      backgroundColor: "#FFC107",
                                      color: "#fff",
                                    }
                                  : {};
                                return (
                                  <tr key={index} style={rowStyle}>
                                    <CopyToClipboard
                                      text={attendancePrice.attendance}
                                      onCopy={() =>
                                        toast.success(
                                          "Atendimento copiado com sucesso!",
                                        )
                                      }
                                    >
                                      <td
                                        style={{
                                          width: "300px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {attendancePrice.attendance}
                                      </td>
                                    </CopyToClipboard>
                                    <td>
                                      <Badge
                                        color={
                                          hasInvalidPrompts
                                            ? "primary"
                                            : "success"
                                        }
                                        style={{ fontSize: "0.9em" }}
                                      >
                                        USD $
                                        {parseFloat(
                                          `${attendancePrice.price}`,
                                        ).toFixed(2)}
                                      </Badge>
                                    </td>
                                    <td>
                                      {attendancePrice.valid_prompts.length}
                                    </td>
                                    <td>
                                      {attendancePrice.invalid_prompts.length}
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </Table>
                    </div>
                  </FormGroup>
                </Col>
              </div>
              <hr className="my-4" />
              <Button
                type="button"
                onClick={submit}
                disabled={
                  !(remainingBudget >= totalPrice) || totalPrice == null
                }
              >
                Iniciar análise
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default UploadAttendances;
