import ReactDateTime from "react-datetime";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

export function DatePicker({ value, onChange }) {
  return (
    <FormGroup
      style={{
        maxWidth: "350px",
        width: "100%",
      }}
    >
      <InputGroup className="input-group-alternative">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="ni ni-calendar-grid-58" />
          </InputGroupText>
        </InputGroupAddon>
        <ReactDateTime
          inputProps={{
            placeholder: "Insira uma data ...",
          }}
          timeFormat={false}
          value={value}
          onChange={onChange}
        />
      </InputGroup>
    </FormGroup>
  );
}
