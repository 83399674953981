import "../assets/css/PromptForm.css";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import qs from "query-string";
import { toast } from "react-toastify";
import api from "service/api";
import Select, { components } from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import ModalPreViewPrompt from "components/Modal/ModalPreViewPrompt";
import CustomInput from "components/Input/CustomInput";

const { MultiValueLabel } = components;

const PromptForm = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);
  const [promptId, setPromptId] = useState("");
  const [segments, setSegments] = useState([]);
  const [segmentId, setSegmentId] = useState("");
  const [hasScore, setHasScore] = useState(false);
  const [hasAlternativeScore, setHasAlternativeScore] = useState(false);
  const [customGeneralRules, setCustomGeneralRules] = useState(false);
  const [promptToUpdateContent, setPromptToUpdateContent] = useState({});
  const [score, setScore] = useState(0);
  const [alternativeScore, setAlternativeScore] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [promptData, setPromptData] = useState({});
  const [selectedSegment, setSelectedSegment] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [selectedTemplateSections, setSelectedTemplateSections] = useState([]);
  const [selectedPreStructuredComponents, setSelectedPreStructuredComponents] =
    useState([]);
  const [templateInfo, setTemplateInfo] = useState([]);
  const [formattedPrompt, setFormattedPrompt] = useState("");
  const [multiFields, setMultiFields] = useState({});
  const [hasTemplate, setHasTemplate] = useState(true);
  const [oldPromptsInfo, setOldPromptsInfo] = useState({});
  const [oldQuestion, setOldQuestion] = useState("");
  const [oldRule, setOldRule] = useState("");
  const [templates, setTemplates] = useState([
    {
      label: "",
      value: "",
    },
  ]);

  const [selectedElement, setSelectedElement] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const [elements, setElements] = useState([]);
  const [availableElements, setAvailableElements] = useState([]);
  const [formData, setFormData] = useState({});

  const handleEnableScoreClick = () => {
    setHasScore(!hasScore);
  };

  const handleEnableAlternativeScoreClick = () => {
    setHasAlternativeScore(!hasAlternativeScore);
  };

  const handleInputChange = (fieldName, type) => (event) => {
    if (type === "select") {
      setFormData({
        ...formData,
        [fieldName]: {
          label: event?.label,
          value: event?.value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: event.target.value,
      });
    }
  };

  const handleTitleChange = (event) => {
    setSelectedTitle(event.target.value);
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate([
      {
        label: event?.label,
        value: event?.value,
      },
    ]);
    if (event?.value) {
      api.get(`/templates/${event?.value}`).then((response) => {
        const sections = response.data.sections?.sort(
          (a, b) => a.position - b.position,
        );
        if (response?.data?.preStructuredComponents) {
          setSelectedPreStructuredComponents(
            response?.data?.preStructuredComponents,
          );
        }
        setSelectedTemplateSections(sections);
      });
    }
  };

  const handleButtonClick = (field) => (e) => {
    e.preventDefault();
    setMultiFields({
      ...multiFields,
      [field]: [...(multiFields[field] || []), formData[field]],
    });
  };

  const handleRemoveClick = (field) => (e, index) => {
    e.preventDefault();
    setMultiFields({
      ...multiFields,
      [field]: multiFields[field].filter((_, i) => i !== index),
    });
  };

  const getParamsId = () => {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  };

  const getTemplates = () => {
    api.get(`/templates?limit=10000`).then((response) => {
      setTemplates(
        response.data.templates.map((item) => ({
          label: item.title,
          value: item.id,
        })),
      );
    });
  };

  useEffect(() => {
    getTemplates();
    const id = getParamsId();
    if (id) {
      setPromptId(id);
      api
        .get(`/prompts/${id}`)
        .then((response) => {
          const prompt = response.data.prompt;

          setPromptToUpdateContent(prompt);
          const question = prompt.replace(
            /^[\s\S]+\{prompt-question\(equals\)([^\]]+?)\}[\s\S]+$/,
            "$1",
          );
          const rule = prompt.replace(
            /^[\s\S]+\{question-rule\(equals\)([^\]]+?)\}[\s\S]+$/,
            "$1",
          );

          setValue("title", response.data.title);
          setSelectedTitle(response.data.title);

          setValue("question", question);
          setValue("rule", rule);
          setSelectedSegment({
            label: response.data?.segment?.title,
            value: response.data?.segment?.id,
          });
          if (!response.data?.template?.id) {
            setOldPromptsInfo(prompt);
            setOldQuestion(question);
            setOldRule(rule);
          }
          if (response.data?.template?.id) {
            setSelectedTemplate([
              {
                label: response.data?.template?.title,
                value: response.data?.template?.id,
              },
            ]);
            api
              .get(`/templates/${response.data?.template?.id}`)
              .then((response) => {
                const sections = response.data.sections?.sort(
                  (a, b) => a.position - b.position,
                );
                if (response?.data?.preStructuredComponents) {
                  setSelectedPreStructuredComponents(
                    response?.data?.preStructuredComponents,
                  );
                }
                setSelectedTemplateSections(sections);
              });
          } else {
            setHasTemplate(false);
          }

          setSelectedElements(
            response.data?.promptReusableElements?.map((elem) => {
              return {
                id: elem.reusableElement.id,
                label: elem.reusableElement.title,
                value: elem.reusableElement.code,
                content: elem.reusableElement.content,
              };
            }),
          );
        })
        .catch((error) => {
          toast.error("Erro ao recuperar o prompt!");
          return;
        });
      setHasId(true);
    }

    api
      .get(`/segments?page=1&limit=10000`)
      .then((response) => {
        setSegments(
          response.data?.segments?.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar os segmentos!");
        return;
      });

    api
      .get(`/reusable-elements?page=1&limit=10000`)
      .then((response) => {
        setAvailableElements(response.data?.reusableElements);
        setElements(
          response.data?.reusableElements?.map((item) => ({
            id: item.id,
            label: item.title,
            value: item.code,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar os elementos!");
        return;
      });
  }, []);

  useEffect(() => {
    const templateRules = [];
    for (let i = 0; i < selectedTemplateSections.length; i++) {
      const promptVariables = [];
      const section = selectedTemplateSections[i];
      const sectionContent = section.content;
      let regex = /\{prompt([^}]+)\}/g;
      let match;
      while ((match = regex.exec(sectionContent))) {
        promptVariables.push(match[0]);
      }
      let regexPlaceholder =
        /\{prompt(?:-field|-multi-field|-textarea|-select)\(equals\)([^}]+)\}/;
      let regexFieldType = /prompt-(.+?)\(equals\)/;

      promptVariables.forEach((variable) => {
        let placeholderMatch = variable.match(regexPlaceholder);
        let formTypeMatch = variable.match(regexFieldType);
        let formFieldPlaceholder;
        let formFieldType;
        if (placeholderMatch?.length) {
          formFieldPlaceholder = placeholderMatch[1].split("(")[0] || [];
        }
        if (formTypeMatch?.length) {
          formFieldType = formTypeMatch[1];
        }
        if (formFieldPlaceholder && formFieldType) {
          templateRules.push({
            formFieldPlaceholder,
            formFieldType,
            position: section.position,
            variable,
            text: section.content,
          });
        }
      });
    }

    templateRules.forEach((item) => {
      if (item.formFieldType === "multi-field") {
        setFormData({
          ...formData,
          [item.formFieldPlaceholder]: item.text
            .replace(item.variable, "")
            .replace("{/prompt}", ""),
        });
      }
      if (item.formFieldType === "select") {
        setFormData({
          ...formData,
          [item.formFieldPlaceholder]: {
            label: item.formFieldPlaceholder,
            value: item.formFieldPlaceholder,
          },
        });
      } else {
        setFormData({
          ...formData,
          [item.formFieldPlaceholder]: "",
        });
      }
    });
    if (hasId) {
      setFormData({
        ...formData,
        question: oldQuestion,
        "question-rules": oldRule,
        pergunta: oldQuestion,
        "regras-da-pergunta": oldRule,
      });
    }
    setTemplateInfo(templateRules);
  }, [selectedTemplate, selectedTemplateSections]);

  useEffect(() => {
    const promptSections = [];
    const templateId = selectedTemplate[0]?.value;
    for (let i = 0; i < selectedTemplateSections.length; i++) {
      const section = selectedTemplateSections[i];
      const sectionContent = section.content.slice();
      let formattedSectionContent = sectionContent;

      for (let j = 0; j < templateInfo.length; j++) {
        const item = templateInfo[j];
        let regexContent = new RegExp(
          "<" +
            "list-" +
            item.formFieldPlaceholder +
            ">(.*?)<" +
            "list-" +
            item.formFieldPlaceholder +
            ">",
        );
        const tag = `<${item.formFieldPlaceholder}>`;
        if (formattedSectionContent.includes(item.variable)) {
          if (item.formFieldType === "select") {
            formattedSectionContent = formattedSectionContent.replace(
              item.variable,
              `${tag}${formData[item.formFieldPlaceholder]?.label}${tag}` || "",
            );
            formattedSectionContent = formattedSectionContent.replace(
              regexContent,
              "",
            );
          }
          if (item.formFieldType === "multi-field") {
            const newFields = multiFields[item.formFieldPlaceholder];
            let contentFields = item.text.match(regexContent);
            let formattedMultiFields;

            if (contentFields?.length) {
              formattedMultiFields = newFields
                ? `\n| ${newFields?.join("\n| ") || ""} \n| ${contentFields[1]
                    .split("|")
                    ?.join("\n| ")} `
                : `\n| ${contentFields[1].split("|")?.join("\n| ")} `;
            }

            if (hasId) {
              formattedMultiFields = `\n| ${newFields?.join("| ") || ""}`;
            }
            formattedSectionContent = formattedSectionContent.replace(
              item.variable,
              `${tag}${formattedMultiFields}${tag}` || "",
            );
            formattedSectionContent = formattedSectionContent.replace(
              regexContent,
              "",
            );
          } else {
            formattedSectionContent = formattedSectionContent.replace(
              item.variable,
              `${tag}${formData[item.formFieldPlaceholder]}${tag}` || "",
            );
          }
        }
      }
      promptSections.push({
        content: formattedSectionContent,
        position: section.position,
      });
    }
    let promptWithSections = promptSections
      .sort((a, b) => a.position - b.position)
      .map((item) => item.content)
      .join("\n\n ### \n\n");
    if (selectedPreStructuredComponents?.length) {
      const jsonFormat = {};
      for (const component of selectedPreStructuredComponents) {
        jsonFormat[component.key] = component.value;
      }
      promptWithSections += `\n\n###\n\nUse apenas o formato JSON para sua resposta:\n\n${JSON.stringify(
        jsonFormat,
        null,
        2,
      )}`;
    }
    const createPrompt = {
      segment_id: selectedSegment?.value,
      template_id: templateId,
      title: selectedTitle,
      prompt: promptWithSections,
    };
    setPromptData(createPrompt);
    setFormattedPrompt(promptWithSections);
  }, [templateInfo, formData, multiFields, selectedSegment, selectedTitle]);

  useEffect(() => {
    const templateId = selectedTemplate[0]?.value;
    if (templateId && getParamsId() && promptToUpdateContent) {
      let updatedPrompt = promptToUpdateContent.slice();
      let newFormData = { ...formData };

      for (let j = 0; j < templateInfo.length; j++) {
        const item = templateInfo[j];
        const tag = `<${item.formFieldPlaceholder}>`;
        const regex = new RegExp(`${tag}(.*?)${tag}`, "g");
        const match = updatedPrompt.match(regex);
        if (match) {
          const value = match[0].replace(tag, "").replace(tag, "");
          if (item.formFieldType === "multi-field") {
            newFormData[item.formFieldPlaceholder] = value.split("|");
          }
          if (item.formFieldType === "select") {
            newFormData[item.formFieldPlaceholder] = {
              label: value,
              value: value,
            };
          } else {
            newFormData[item.formFieldPlaceholder] = value;
          }
        } else {
          if (item.formFieldType === "textarea") {
            const openingTag = `<${item.formFieldPlaceholder}>`;

            const firstIndex = updatedPrompt.indexOf(openingTag);
            const secondIndex = updatedPrompt.indexOf(
              openingTag,
              firstIndex + openingTag.length,
            );

            if (firstIndex !== -1 && secondIndex !== -1) {
              const content = updatedPrompt.substring(
                firstIndex + openingTag.length,
                secondIndex,
              );
              newFormData[item.formFieldPlaceholder] = content;
            }
          }
          if (item.formFieldType === "multi-field") {
            const openingTag = `<${item.formFieldPlaceholder}>`;

            const firstIndex = updatedPrompt.indexOf(openingTag);
            const secondIndex = updatedPrompt.indexOf(
              openingTag,
              firstIndex + openingTag.length,
            );

            if (firstIndex !== -1 && secondIndex !== -1) {
              const content = updatedPrompt.substring(
                firstIndex + openingTag.length,
                secondIndex,
              );
              setMultiFields({
                ...multiFields,
                [item.formFieldPlaceholder]: content
                  .split("|")
                  .filter((item) => item !== "\n"),
              });
            }
          }
        }
      }
      setFormData(newFormData);
    }
  }, [templateInfo, promptToUpdateContent]);

  const onChangeScore = (e) => {
    let value = parseInt(e.target.value);
    if (value < 0 || value == " " || value == "" || isNaN(value)) {
      value = 0;
    }
    if (!isNaN(value)) {
      setScore(value);
    }
  };

  const onChangeAlternativeScore = (e) => {
    let value = parseInt(e.target.value);
    if (value < 0 || value == " " || value == "" || isNaN(value)) {
      value = 0;
    }
    if (!isNaN(value)) {
      setAlternativeScore(value);
    }
  };

  const CustomOption = ({ children, ...props }) => (
    <MultiValueLabel {...props}>
      <CopyToClipboard
        text={"@" + props.data.value}
        onCopy={() => {
          toast.success("Código copiado com sucesso!");
        }}
        title="Clique para copiar o código"
      >
        <button
          style={{ border: "none" }}
          type="button"
          onClick={() => {
            setSelectedElement(
              elements.filter((item) => item.value == props.data.value)[0],
            );
          }}
        >
          {children}
        </button>
      </CopyToClipboard>
    </MultiValueLabel>
  );

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="" xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center ml-2">
                <h3 className="m-0">
                  {hasId ? "Atualizar Prompt" : "Cadastrar prompt"}
                </h3>
                {!hasTemplate && (
                  <i className="ni ni-settings text-red ml-2"></i>
                )}
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Título
                      </label>
                      <input
                        className="form-control-alternative styled-input custom-input"
                        id="prompt-title"
                        placeholder="Título"
                        value={selectedTitle}
                        onChange={handleTitleChange}
                        type="text"
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="client">
                        Segmento
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Escolha um segmento"
                        value={selectedSegment}
                        onChange={setSelectedSegment}
                        isClearable={true}
                        isSearchable={true}
                        options={segments}
                      />
                    </FormGroup>
                  </Col>
                </div>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="question">
                        Elementos reutilizáveis vinculados (selecione para
                        copiar)
                      </label>
                      <Select
                        defaultValue={selectedElements}
                        isMulti
                        name="colors"
                        options={Object.values(elements)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Selecione os elementos"
                        components={{
                          MultiValueLabel: CustomOption,
                        }}
                        value={selectedElements}
                        onChange={(selected) => {
                          setSelectedElements(selected);
                          const lastOption = selected[selected.length - 1];
                          setSelectedElement(lastOption);
                          if (lastOption) {
                            navigator.clipboard.writeText(
                              "@" + lastOption.value,
                            );
                          }
                        }}
                      />
                      <h4
                        style={{
                          backgroundColor: "#117A65",
                          padding: "10px",
                          borderTopRightRadius: "10px",
                          borderTopLeftRadius: "10px",
                          color: "#fff",
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Prévia do elemento: {selectedElement?.label}
                        <CopyToClipboard
                          text={"@" + selectedElement?.value}
                          onCopy={() =>
                            toast.success("Código copiado com sucesso!")
                          }
                          title="Clique para copiar o código"
                        >
                          <Button type="button" color="primary">
                            @{selectedElement?.value}
                          </Button>
                        </CopyToClipboard>
                      </h4>
                      <h4
                        style={{
                          borderRadius: "4px",
                          backgroundColor: "#D6EAF8",
                          padding: "10px",
                        }}
                      >
                        {selectedElement && availableElements
                          ? availableElements.filter(
                              (element) =>
                                element.code == selectedElement?.value,
                            )[0]?.content
                          : ""}
                      </h4>
                    </FormGroup>
                  </Col>
                </div>
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="question">
                        Selecione um template
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Escolha um template"
                        value={selectedTemplate}
                        onChange={handleTemplateChange}
                        isClearable={true}
                        isSearchable={true}
                        options={templates}
                      />
                    </FormGroup>
                  </Col>
                </div>
                <hr className="my-4" />
                <div className="pl-lg-12">
                  <Col className="pl-lg-12">
                    {templateInfo &&
                      templateInfo.map((item, index) => (
                        <CustomInput
                          key={index}
                          label={item.formFieldPlaceholder}
                          text={item.text}
                          type={item.formFieldType}
                          handleButton={handleButtonClick(
                            item.formFieldPlaceholder,
                          )}
                          onChange={handleInputChange(
                            item.formFieldPlaceholder,
                            item.formFieldType,
                          )}
                          value={formData[item.formFieldPlaceholder]}
                          multiFields={multiFields}
                          handleRemoveClick={handleRemoveClick(
                            item.formFieldPlaceholder,
                          )}
                          hasId={hasId}
                        />
                      ))}
                  </Col>
                </div>
                <div>
                  <div className="pl-lg-12"></div>
                  {templateInfo.length ? <hr className="my-4" /> : <></>}
                  <ModalPreViewPrompt
                    prompt={formattedPrompt}
                    promptData={promptData}
                    promptId={getParamsId()}
                    score={score}
                    alternativeScore={alternativeScore}
                    hasScore={hasScore}
                    hasAlternativeScore={hasAlternativeScore}
                    selectedElements={selectedElements}
                    availableElements={availableElements}
                  />
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default PromptForm;
